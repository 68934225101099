import {api} from "AurionCR/components";
import {API_PHARMA_DEPARTMENTS, PharmaCompanyDepartment} from "./models";
import {PatchModel, setToModel} from "utils";

export * from './models';

class Service {
    async getByID(id: string) {
        return api.get<PharmaCompanyDepartment>(API_PHARMA_DEPARTMENTS.GET(id));
    }

    async create(data: PharmaCompanyDepartment) {
        return api.post(API_PHARMA_DEPARTMENTS.POST, data);
    }

    async patch(data: PatchModel<PharmaCompanyDepartment, 'id'>) {
        return api.patch(API_PHARMA_DEPARTMENTS.PATCH(data), setToModel(new PharmaCompanyDepartment(), data))
    }

    async delete(data: { id: string }) {
        return api.post(API_PHARMA_DEPARTMENTS.DELETE(data));
    }
}

export const ServicePharmaDepartment = new Service();
