import {PatchModel, prepareDateToSave} from "utils";
import {api} from "AurionCR/components";
import {API_MEDICAL_EVENTS_TARGET_AUDIENCES, MedicalEventTargetAudience} from "./models";

export * from './models'

class Service {
  async post(data: MedicalEventTargetAudience) {
    const params = {
      Filter: `medicalEventID == "${data.medicalEventID}"`,
      Take: 1,
      OrderBy: 'rank desc'
    }
    const result = await api.get(API_MEDICAL_EVENTS_TARGET_AUDIENCES.GET_ALL_DYNAMIC, {params})
    const rank = (result.data.value[0]?.rank || 0) + 1
    return api.post(API_MEDICAL_EVENTS_TARGET_AUDIENCES.POST, prepareDateToSave({...data, rank}))
  }
  async patch(data: PatchModel<MedicalEventTargetAudience, 'id'>) {
    return api.patch(API_MEDICAL_EVENTS_TARGET_AUDIENCES.PATCH(data), prepareDateToSave({...data}))
  }
}

export const ServiceMedicalEventTargetAudience = new Service()
