import {PharmaCompany} from "../pharma";

const _API = 'PharmaCompanyDepartments';

export const API_PHARMA_DEPARTMENTS ={
    API: _API,
    GET: (id: string) => `${_API}/Get/${id}`,
    GET_ALL_DYNAMIC: `${_API}/GetAllDynamic`,
    POST: `${_API}/Create`,
    PATCH: ({id}: Pick<PharmaCompany, 'id'>) => `${_API}/Patch/${id}`,
    DELETE: ({id}: Pick<PharmaCompany, 'id'>) => `${_API}/Delete/${id}`
}

export class PharmaCompanyDepartment {
    id!: string;
    pharmaCompanyID: string = '';
    title: string = '';
    isActive: boolean = true;
}
