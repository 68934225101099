const API = 'UserStatuses'

export const API_USER_STATUSES = {
  API,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({id}: Pick<UserStatus, 'id'>) => `${API}/Patch/${id}`,
  DELETE: ({id}: Pick<UserStatus, 'id'>) => `${API}/Delete/${id}`
}

export class UserStatus {
  id!: string
  isActive: boolean = false
  title: string = ''
}
