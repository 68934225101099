export enum LABELS_KEY {
  EXPERT = 'expert',
  PHARMA = 'pharma',
  CRM = 'crm',
  WEBSITES = 'websites'
}

const _API = 'Labels'

export const API_LABELS = {
  API: _API,
  GET_ALL_DYNAMIC: `${_API}/GetAllDynamic`,
  POST: `${_API}/Create`,
  PATCH: ({ id }: Pick<Label, 'id'>) => `${_API}/Patch/${id}`,
  DELETE: ({ id }: Pick<Label, 'id'>) => `${_API}/Delete/${id}`
}

export class LabelSource {
  id!: string
  key: LABELS_KEY = LABELS_KEY.CRM
  title: string = ''
}

export class Label {
  id!: number
  title: string = ''
  labelKey: string = ''
  longDescription: string = ''
  languageID!: string
  labelSourceID!: string
  isActive: boolean = true
}

const API = 'Languages'

export const API_LANGUAGES = {
  API: _API,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({ id }: Pick<Label, 'id'>) => `${API}/Patch/${id}`,
  DELETE: ({ id }: Pick<Label, 'id'>) => `${API}/Delete/${id}`
}
export class Language {
  id!: string
  title: string = ''
  direction: number = 1
}
