import {api} from "AurionCR/components";
import {PatchModel, setToModel} from "utils";
import {API_USER_PHARMA_COMPANY, PatchUserPharmaCompanyProfile, UserPharmaCompanyProfile} from "./models";

export * from './models'

class Service {
  async patch(data: PatchModel<UserPharmaCompanyProfile, 'appIdentityUserID'>) {
    return api.patch(API_USER_PHARMA_COMPANY.PATCH(data), setToModel(new PatchUserPharmaCompanyProfile(), data))
  }

  async delete(data: { appIdentityUserID: string }) {
    return api.post(API_USER_PHARMA_COMPANY.DELETE(data))
  }
}

export const ServiceUserPharmaCompany = new Service()
