import { useMemo } from 'react';
import { useI18n, useSource } from 'AurionCR/components';
import { API_LANGUAGES, Language } from 'services/labels';
import { API_TAGS, Tag } from 'services/tags';
import { API_PHARMA } from 'services/pharma';
import { API_MEDICAL_FIELDS, MedicalField } from 'services/medical-fields';
import { API_PHARMA_USERS, PharmaCompanyUser } from 'services/pharma-users';
import { API_MEMBERS } from 'services/members';
import { API_EXPERT_TYPES, ExpertType } from 'services/expert-types';
import { API_USER_STATUSES } from 'services/user-statuses';
import {
  API_MEDICAL_EVENTS_TARGET_AUDIENCES,
  MedicalEventTargetAudience,
} from 'services/medica-event-target-audiences';
import { API_TITLE_OF_NAMES } from 'services/title-of-names';
import { API_ARTICLE_CATEGORIES } from 'services/article-categories';
import { API_USER_PHARMA_COMPANY } from 'services/user-pharma-company';
import { API_HMOS, Hmo } from 'services/hmos';
import { API_CITIES, City } from 'services/cities';
import { API_HOSPITALS, Hospital } from 'services/hospitals';
import { API_DEPARTMENTS, Department } from 'services/departments';
import { API_WORK_PLACES, WorkPlace } from 'services/work-places';
import { API_PHARMA_DEPARTMENTS, PharmaCompanyDepartment } from '../services/pharma-departments';

type DefaultSource = { id: string; title: string };
export const useSourcesLanguages = () => {
  return useSource<Language>(
    `${API_LANGUAGES.GET_ALL_DYNAMIC}?select=id,title,direction`,
    'id',
    true,
  );
};
export const useSourcesTags = () => {
  return useSource<Tag>(
    `${API_TAGS.GET_ALL_DYNAMIC}?select=id,title&filter=isActive==true&orderBy=title asc`,
    'id',
    true,
  );
};
export const useSourcesExperts = () => {
  const filter = encodeURIComponent('isActive==true&&expertType.physician==true');
  return useSource<DefaultSource>(
    `${API_MEMBERS.GET_ALL_DYNAMIC}?select=appIdentityUserID as id,fullName as title&filter=${filter}&orderBy=firstName,lastName asc`,
    'id',
    true,
  );
};
export const useSourcesPharmaCompanies = () => {
  return useSource<DefaultSource>(
    `${API_PHARMA.GET_ALL_DYNAMIC}?select=id,companyName as title&filter=isActive==true&orderBy=companyName asc`,
    'id',
    true,
  );
};
export const useSourceArticleCategories = () => {
  return useSource<DefaultSource>(
    `${API_ARTICLE_CATEGORIES.GET_ALL_DYNAMIC}?filter=isActive==true&orderBy=rank desc`,
  );
};
export const useSourcesPharmaCompanyUsers = (companyID?: string) => {
  const filter = ['isActive==true', companyID && `pharmaCompanyID == "${companyID}"`]
    .filter((v) => !!v)
    .join('&&');
  const select = Object.keys(new PharmaCompanyUser()).join(',');
  return useSource<PharmaCompanyUser>(
    `${API_PHARMA_USERS.GET_ALL_DYNAMIC}?select=${select}&filter=${encodeURIComponent(filter)}`,
    'id',
    true,
  );
};
export const useSourcesMedicalFields = () => {
  return useSource<MedicalField>(
    `${API_MEDICAL_FIELDS.GET_ALL_DYNAMIC}?select=id,title&filter=isActive==true&orderBy=title asc`,
    'id',
    true,
  );
};
export const useSourcesMembers = () => {
  return useSource<DefaultSource>(
    `${API_MEMBERS.GET_ALL_DYNAMIC}?select=appIdentityUserID as id,${encodeURIComponent(
      `(firstName + " " + lastName) as title`,
    )}&filter=isActive==true&orderBy=firstName,lastName asc`,
    'id',
    true,
  );
};
export const useSourcesExpertTypes = () => {
  return useSource<ExpertType>(
    `${API_EXPERT_TYPES.GET_ALL_DYNAMIC}?filter=isActive==true&orderBy=title asc`,
    'id',
    true,
  );
};
export const useSourcesTitleOfNames = () => {
  return useSource<{ title: string; id: string }>(
    `${API_TITLE_OF_NAMES.GET_ALL_DYNAMIC}?filter=isActive==true&orderBy=title asc`,
    'id',
    false,
  );
};
export const useSourcesExpertTypesVisitors = () => {
  const res = useSourcesExpertTypes();
  return useMemo(
    () => ({
      ...res,
      data: res.data.filter(({ visitor }) => visitor),
    }),
    [res],
  );
};
export const useSourcesUserStatuses = () => {
  const { t, loading } = useI18n();

  const result = useSource<DefaultSource>(
    `${API_USER_STATUSES.GET_ALL_DYNAMIC}?select=id,title&filter=isActive==true&orderBy=title asc`,
    'id',
    true,
  );
  return useMemo(() => {
    return {
      ...result,
      data: result.data.map((item) => ({ ...item, title: t(item.title), key: item.title })),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, loading]);
};
export const useSourceTargetAudience = (eventID?: string) => {
  return useSource<MedicalEventTargetAudience & { title: string }>(
    `${API_MEDICAL_EVENTS_TARGET_AUDIENCES.GET_ALL_DYNAMIC}?select=id,expertTypeID,expertType.title as title&filter=medicalEventID=="${eventID}"`,
  );
};
export const useSourceUserPharmaCompanyProfiles = () => {
  return useSource<DefaultSource>(
    `${API_USER_PHARMA_COMPANY.GET_ALL_DYNAMIC}?select=appIdentityUserID as id,fullName as title`,
  );
};
export const useSourcesHmoTypes = () => {
  return useSource<Hmo>(
    `${API_HMOS.GET_ALL_DYNAMIC}?filter=isActive==true&orderBy=title asc`,
    'id',
    true,
  );
};
export const useSourcesCities = () => {
  return useSource<City>(
    `${API_CITIES.GET_ALL_DYNAMIC}?filter=isActive==true&orderBy=title asc`,
    'id',
    true,
  );
};
export const useSourcesHospitals = () => {
  return useSource<Hospital>(
    `${API_HOSPITALS.GET_ALL_DYNAMIC}?filter=isActive==true&orderBy=title asc`,
    'id',
    true,
  );
};
export const useSourcesDepartments = () => {
  return useSource<Department>(
    `${API_DEPARTMENTS.GET_ALL_DYNAMIC}?filter=isActive==true&orderBy=title asc`,
    'id',
    true,
  );
};
export const useSourcesPharmaDepartments = () => {
  return useSource<PharmaCompanyDepartment>(
    `${API_PHARMA_DEPARTMENTS.GET_ALL_DYNAMIC}?filter=isActive==true&orderBy=title asc`,
    'id',
    true,
  );
};
export const useSourcesWorkPlaces = () => {
  return useSource<WorkPlace>(
    `${API_WORK_PLACES.GET_ALL_DYNAMIC}?filter=isActive==true&orderBy=title asc`,
    'id',
    true,
  );
};
