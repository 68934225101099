const API_ENDPOINT = 'Cities'

export const API_CITIES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: { id: City['id'] }) =>  `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: { id: City['id'] }) => `${API_ENDPOINT}/Delete/${data.id}`,
}

export class City {
  id!: string
  title: string = ''
  isActive: boolean = true
}
