import { format, isValid } from 'date-fns';
export const prepareDateToSave = <M extends { [x: string]: any | Date }>(data: M) => {
  let _data = { ...data };
  Object.keys(data).forEach((key: keyof M) => {
    // @ts-ignore
    _data[key] =
      // @ts-ignore
      data[key] && data[key] instanceof Date
        ? // @ts-ignore
          isValid(data[key])
          ? format(data[key], "yyyy-MM-dd'T'HH:mm:ss")
          : data[key]
        : data[key];
  });

  return _data;
};

export const normalizeDataBeforeSave = <T extends Record<string, any>>(data: T) => {
  return Object.keys(data).reduce((acc, key) => {
    const value = data[key];
    // @ts-ignore
    acc[key] = String(key).endsWith('ID') && value === '' ? null : value;
    return acc;
  }, {} as T);
};
export const parseDataFromServer = <T extends { [x: string]: any }>(data: T) => {
  const keys = Object.keys(data) as (keyof T)[];
  return keys.reduce((res, key) => {
    return {
      ...res,
      [key]: res[key] === null ? '' : res[key],
    };
  }, data);
};
