import {api} from "AurionCR/components";
import {API_ARTICLE_CATEGORIES, ArticleCategory} from "./models";

export * from './models'

class Service {
  async post(data: ArticleCategory) {
    const params = {
      Take: 1,
      OrderBy: 'rank desc'
    }
    const result = await api.get(API_ARTICLE_CATEGORIES.GET_ALL_DYNAMIC, {params})
    const rank = (result.data.value[0]?.rank || 0) + 1
    return api.post(API_ARTICLE_CATEGORIES.POST, {...data, rank})
  }
}

export const ServiceArticleCategories = new Service()
