import _ from 'lodash';

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};
export type PatchModel<T extends {}, ID extends keyof T> = Pick<T, ID> & Partial<Omit<T, ID>>;
export type ArrayModel<T> = T extends (infer U)[] ? U : never;

export const setToModel = <Model extends { [x: string]: any }>(
  model: Model,
  source: DeepPartial<Model>,
) => {
  const result = _.mergeWith(
    { ...model },
    source,
    (valueModel, valueSource, key, objMod, objSource) => {
      if (Array.isArray(valueModel) && Array.isArray(source)) {
        return source;
      }
    },
  );
  return _.pick(result, Object.keys(model)) as Model;
};

export const createObjectMap = <M extends any[]>(
  source: M,
  key: keyof ArrayModel<M>,
): { [x: string]: undefined | ArrayModel<M> } => {
  return source.reduce((acc, item) => {
    acc[item[key]] = item;
    return acc;
  }, {});
};
