const _API = 'ArticleCategories'

export const API_ARTICLE_CATEGORIES = {
  API: _API,
  GET_ALL_DYNAMIC: `${_API}/GetAllDynamic`,
  POST: `${_API}/Create`,
  PATCH: ({id}: Pick<ArticleCategory, 'id'>) => `${_API}/Patch/${id}`,
  DELETE: ({id}: Pick<ArticleCategory, 'id'>) => `${_API}/Delete/${id}`
}

export class ArticleCategory {
  id!: string
  title = ''
  icon = ''
  iconSecondary = ''
  rank = ''
  isActive: boolean = true
}
