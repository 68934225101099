import { IDataGridColumn } from 'AurionCR/components/data-grid/model';
import { ModuleExportColumn } from 'modules/export-excel/models';

export const FORMAT_DATE = 'dd.MM.yyyy';
export const calcFilterDateMultiple = ({ field, value }: { field: string; value: any[] }) => {
  return `${field}.Date >= DateTime(${value[0]}) && ${field}.Date <= DateTime(${value[1]})`;
};
export const calcFilterFullName = ({
  firstName = 'firstName',
  lastName = 'lastName',
  value,
}: {
  firstName?: string;
  lastName?: string;
  value: string;
}) => {
  return `(${firstName}.replace(" ", String.Empty) + ${lastName}.replace(" ", String.Empty)).contains("${value.replace(
    / /gi,
    '',
  )}")`;
};
export const calcFilterSelect = <Model>({ name, value }: { name: keyof Model; value: string }) => {
  return value ? `${name} == ${value}` : undefined;
};
export const calcSearchMember = <Model>({ name, value }: { name: keyof Model; value: string }) => {
  const fields = ['fullName', 'externalCertificateLicenseID', 'mobilePhone'] as const;

  return value
    ? `(${fields
        .map((field) => {
          switch (field) {
            case 'fullName':
              return calcFilterFullName({ value });
            default:
              return `${field}.contains("${value}")`;
          }
        })
        .join(' || ')})`
    : undefined;
};

const getColumnField = (fieldGridColumn: string): string => {
  const arr = fieldGridColumn.split(' ');
  return arr[arr.length - 1];
};
export const gidColumnsToExcel = (columns: IDataGridColumn[]): ModuleExportColumn[] => {
  return columns.map((column) => {
    return {
      title: column.title,
      field: getColumnField(column.field),
      // @ts-ignore
      type: column.type,
      // @ts-ignore
      valueTemplate: column.valueTemplate,
    };
  });
};

export const prepareRequest = <T extends { [x: string]: any | null } = {}>(data: T) => {
  const keys = Object.keys(data) as (keyof T)[];
  return keys.reduce((acc, key) => {
    // @ts-ignore
    acc[key] = data[key] === '' ? null : data[key];
    return acc;
  }, data);
};
