import { useCallback } from 'react';
import { createFilterOptions } from '@material-ui/lab';

interface Props<T extends { [x: string]: any }> {
  keyLabel: keyof T;
  onCreate: (inputValue: string) => void;
}

export const useSelectFreeSolo = <T extends { [x: string]: any }>(p: Props<T>) => {
  const { keyLabel, onCreate } = p;
  const filterOptions = useCallback(
    (options, params) => {
      const filter = createFilterOptions<T>();
      const filtered = filter(options, params);

      if (params.inputValue !== '') {
        // @ts-ignore
        filtered.push({
          inputValue: params.inputValue,
          [keyLabel]: `+ "${params.inputValue}"`,
        });
      }

      return filtered;
    },
    [keyLabel],
  );
  const onChange = useCallback(
    (e, newValue, option, details) => {
      if (typeof option === 'string') {
        onCreate(option);
        return [''];
      }
      if (option && option.inputValue) {
        onCreate(option.inputValue);
        return [''];
      }
      return [e?.target?.value || newValue];
    },
    [onCreate],
  );
  return {
    filterOptions,
    onChange,
  };
};
