const API_ENDPOINT = 'Hospitals'

export const API_HOSPITALS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: { id: Hospital['id'] }) =>  `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: { id: Hospital['id'] }) => `${API_ENDPOINT}/Delete/${data.id}`,
}

export class Hospital {
  id!: string
  title: string = ''
  isActive: boolean = true
}
