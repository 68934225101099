import { lazy } from 'react';
import { IRoute } from 'AurionCR/components/router';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import SettingsIcon from '@material-ui/icons/Settings';
import PeopleIcon from '@material-ui/icons/People';
import { PERMISSIONS } from './constants';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import EventIcon from '@material-ui/icons/Event';
import LocalPharmacyIcon from '@material-ui/icons/LocalPharmacy';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AssignmentIcon from '@material-ui/icons/Assignment';

interface IRouteApp extends IRoute {
  permission?: PERMISSIONS;
  routes?: undefined | IRouteApp[];
}

const routes: IRouteApp[] = [
  {
    path: '/',
    title: 'dashboard',
    icon: DashboardIcon,
    component: lazy(() => import(/* webpackChunkName: 'Dashboard' */ 'pages/Dashboard')),
  },
  {
    path: '/members',
    title: 'members',
    // permission: PERMISSIONS.EDIT_CRM_USERS,
    icon: PeopleIcon,
    routes: [
      {
        path: '/experts-list',
        title: 'experts-list',
        component: lazy(
          () => import(/* webpackChunkName: 'ExpertsList' */ 'pages/Members/experts-list'),
        ),
      },
      {
        path: '/doctors-list',
        title: 'doctors-list',
        component: lazy(
          () => import(/* webpackChunkName: 'DoctorsList' */ 'pages/Members/doctors-list'),
        ),
      },
      {
        path: '/visitors-list',
        title: 'visitors-list',
        component: lazy(
          () => import(/* webpackChunkName: 'VisitorsList' */ 'pages/Members/visitors-list'),
        ),
      },
      {
        path: '/external-users',
        title: 'external-users',
        component: lazy(
          () => import(/* webpackChunkName: 'DoctorsList' */ 'pages/Members/external-users'),
        ),
      },
      {
        path: '/license-validator-report',
        title: 'license-validator-report',
        component: lazy(
          () =>
            import(
              /* webpackChunkName: 'LicenseValidatorReport' */ 'pages/Members/license-validator-report'
            ),
        ),
      },
      {
        path: '/messages',
        title: 'chat-messages',
        component: lazy(
          () => import(/* webpackChunkName: 'ChatMessages' */ 'pages/Members/chat-messages'),
        ),
      },
    ],
  },
  {
    path: '/pharma',
    title: 'pharma-companies',
    icon: LocalPharmacyIcon,
    routes: [
      {
        path: '/index',
        title: 'pharma-companies-list',
        component: lazy(() => import(/* webpackChunkName: 'Pharma' */ 'pages/PharmaList')),
      },
    ],
  },
  {
    path: '/articles',
    title: 'articles',
    icon: AssignmentIcon,
    routes: [
      {
        path: '/index',
        title: 'members-articles',
        component: lazy(() => import(/* webpackChunkName: 'Articles' */ 'pages/ArticlesMember')),
      },
      {
        path: '/articles',
        title: 'pharma-company-articles',
        component: lazy(() => import(/* webpackChunkName: 'Articles' */ 'pages/ArticlesCompany')),
      },
    ],
  },
  {
    path: '/events',
    title: 'events',
    icon: EventIcon,
    private: true,
    routes: [
      {
        path: '/index',
        private: true,
        title: 'medical-events',
        component: lazy(
          () => import(/* webpackChunkName: 'MedicalEvents' */ 'pages/MedicalEvents'),
        ),
      },
    ],
  },
  {
    path: '/surveys',
    title: 'surveys',
    icon: ListAltIcon,
    routes: [
      {
        path: '/index',
        title: 'surveys-list',
        component: lazy(() => import(/* webpackChunkName: 'Surveys' */ 'pages/SurveysList')),
      },
    ],
  },
  {
    path: '/campaigns',
    title: 'campaigns',
    icon: TrackChangesIcon,
    routes: [
      {
        path: '/index',
        title: 'campaigns',
        component: lazy(() => import(/* webpackChunkName: 'Campaigns' */ 'pages/Campaigns')),
      },
      {
        path: '/email-templates',
        title: 'email-templates',
        component: lazy(
          () => import(/* webpackChunkName: 'EmailTemplates' */ 'pages/EmailTemplates'),
        ),
      },
      {
        path: '/sms-messages',
        title: 'sms-messages',
        component: lazy(() => import(/* webpackChunkName: 'SMSContents' */ 'pages/SMSContents')),
      },
      {
        path: '/email-service-providers',
        title: 'email-service-providers',
        component: lazy(
          () =>
            import(/* webpackChunkName: 'EmailServiceProviders' */ 'pages/EmailServiceProviders'),
        ),
      },
    ],
  },
  {
    path: '/app-settings',
    title: 'app-settings',
    icon: SettingsApplicationsIcon,
    permission: PERMISSIONS.EDIT_WEBSITES,
    routes: [
      {
        path: '/tags',
        title: 'tags',
        component: lazy(() => import(/* webpackChunkName: 'AppPage' */ 'pages/Tags')),
      },
      {
        path: '/departments',
        title: 'departments',
        component: lazy(() => import(/* webpackChunkName: 'AppPage' */ 'pages/Departments')),
      },
      {
        path: '/cities',
        title: 'cities',
        component: lazy(() => import(/* webpackChunkName: 'AppPage' */ 'pages/Cities')),
      },
      {
        path: '/hmos',
        title: 'hmos',
        component: lazy(() => import(/* webpackChunkName: 'AppPage' */ 'pages/Hmos')),
      },
      {
        path: '/hospitals',
        title: 'hospitals',
        component: lazy(() => import(/* webpackChunkName: 'AppPage' */ 'pages/Hospitals')),
      },
      {
        path: '/medical-fields',
        title: 'medical-fields',
        component: lazy(
          () => import(/* webpackChunkName: 'MedicalFields' */ 'pages/MedicalFields'),
        ),
      },
      {
        path: '/credit-points',
        title: 'credit-points',
        component: lazy(() => import(/* webpackChunkName: 'AppPage' */ 'pages/CreditPoints')),
      },
      {
        path: '/title-of-names',
        title: 'title-of-names',
        component: lazy(() => import(/* webpackChunkName: 'AppPage' */ 'pages/TitleOfNames')),
      },
      {
        path: '/speak-languages',
        title: 'speak-languages',
        component: lazy(() => import(/* webpackChunkName: 'AppPage' */ 'pages/SpeakLanguages')),
      },
      {
        path: '/work-places',
        title: 'work-places',
        component: lazy(() => import(/* webpackChunkName: 'AppPage' */ 'pages/WorkPlaces')),
      },
      {
        path: '/email-subjects',
        title: 'email-subjects',
        component: lazy(() => import(/* webpackChunkName: 'AppPage' */ 'pages/EmailSubjects')),
      },
      {
        path: '/article-categories',
        title: 'article-categories',
        component: lazy(
          () => import(/* webpackChunkName: 'ArticleCategories' */ 'pages/ArticleCategories'),
        ),
      },
      {
        path: '/document-types',
        title: 'document-types',
        component: lazy(
          () => import(/* webpackChunkName: 'ArticleCategories' */ 'pages/DocumentTypes'),
        ),
      },
      {
        path: '/message-reply-templates',
        title: 'message-reply-templates',
        component: lazy(
          () => import(/* webpackChunkName: 'ArticleCategories' */ 'pages/MessageReplyTemplates'),
        ),
      },
    ],
  },
  {
    path: '/cms',
    title: 'cms',
    icon: AnnouncementIcon,
    routes: [
      {
        path: '/expert',
        title: 'page-expert',
        component: lazy(
          () => import(/* webpackChunkName: 'CommonPages' */ 'pages/CommonPages/expert'),
        ),
      },
      {
        path: '/pharma',
        title: 'page-pharma-company',
        component: lazy(
          () => import(/* webpackChunkName: 'CommonPages' */ 'pages/CommonPages/pharma'),
        ),
      },
      {
        path: '/privacy',
        title: 'page-privacy',
        component: lazy(
          () => import(/* webpackChunkName: 'CommonPages' */ 'pages/CommonPages/privacy'),
        ),
      },
      {
        path: '/terms',
        title: 'page-terms',
        component: lazy(
          () => import(/* webpackChunkName: 'CommonPages' */ 'pages/CommonPages/terms'),
        ),
      },
      {
        path: '/app',
        title: 'page-app',
        component: lazy(
          () => import(/* webpackChunkName: 'CommonPages' */ 'pages/CommonPages/app'),
        ),
      },
    ],
  },
  {
    path: '/settings',
    title: 'settings',
    icon: SettingsIcon,
    permission: PERMISSIONS.EDIT_SETTINGS,
    routes: [
      {
        path: '/users',
        title: 'users-crm',
        component: lazy(() => import(/* webpackChunkName: 'Users' */ 'pages/Users')),
      },
      {
        path: '/permissions',
        title: 'permissions',
        permission: PERMISSIONS.EDIT_PERMISSIONS,
        component: lazy(() => import(/* webpackChunkName: 'Permissions' */ 'pages/Permissions')),
      },
      {
        path: '/site-parameters',
        title: 'site-parameters',
        component: lazy(
          () => import(/* webpackChunkName: 'SiteParameters' */ 'pages/SiteParameters'),
        ),
      },
      {
        path: '/external-channels',
        title: 'external-channels',
        permission: PERMISSIONS.EDIT_PERMISSIONS,
        component: lazy(
          () => import(/* webpackChunkName: 'ExternalChannels' */ 'pages/ExternalChannels'),
        ),
      },
      {
        path: '/labels-crm',
        title: 'labels-crm',
        component: lazy(() => import(/* webpackChunkName: 'Labels' */ 'pages/Labels/crm')),
      },
      {
        path: '/labels-expert',
        title: 'labels-expert',
        component: lazy(() => import(/* webpackChunkName: 'Labels' */ 'pages/Labels/expert')),
      },
      {
        path: '/labels-pharma',
        title: 'labels-pharma',
        component: lazy(() => import(/* webpackChunkName: 'Labels' */ 'pages/Labels/pharma')),
      },
      {
        path: '/labels-web',
        title: 'labels-website',
        component: lazy(() => import(/* webpackChunkName: 'Labels' */ 'pages/Labels/websites')),
      },
      {
        path: '/log',
        title: 'log',
        permission: PERMISSIONS.VIEW_LOG,
        component: lazy(() => import(/* webpackChunkName: 'Log' */ 'pages/Log')),
      },
      {
        path: '/expert-types',
        title: 'expert-types',
        permission: PERMISSIONS.EDIT_PERMISSIONS,
        component: lazy(() => import(/* webpackChunkName: 'ExpertTypes' */ 'pages/ExpertTypes')),
      },
    ],
  },
  {
    path: '/profile',
    title: 'profile',
    inMenu: false,
    component: lazy(() => import(/* webpackChunkName: 'Profile' */ 'pages/Profile')),
  },
  {
    path: '/login',
    title: 'login',
    inMenu: false,
    private: false,
    inLayout: false,
    component: lazy(() => import(/* webpackChunkName: 'Login' */ 'pages/Login')),
  },
];

export default routes;
