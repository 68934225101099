import { useCallback, useState } from 'react';
import { IDataGridColumn } from 'AurionCR/components/data-grid/model';
import { api, requestError, useI18n } from 'AurionCR/components';
import { ModuleExportExcel } from 'modules/export-excel';
import { gidColumnsToExcel } from 'components/helpers';
import { notifyRequestResult } from 'AurionCR/store/modules/notify';
import { useDispatch } from 'react-redux';

export const useExportToExcel = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useI18n();

  const dispatch = useDispatch();
  const exportToExcel = useCallback(
    async (p: { url: string; params: any; columns: IDataGridColumn[]; title: string }) => {
      const { columns, url, title } = p;
      setLoading(true);
      try {
        const params = {
          select: columns.map(({ field }: any) => field).join(','),
        };
        const { data } = await api.get(url, { params: { ...params, ...p.params } });
        const excelColumns = gidColumnsToExcel(columns);

        const _columns = excelColumns.map((item) => ({ ...item, title: t(item.title) }));

        const { wb_out } = await ModuleExportExcel.export({
          columns: _columns,
          data: data.value,
          settings: { title },
        });
        await ModuleExportExcel.save({ wb_out, name: title });
        dispatch(notifyRequestResult('success'));
      } catch (e: any) {
        dispatch(notifyRequestResult(requestError(e.message), 'error'));
      } finally {
        setLoading(false);
      }
    },
    [dispatch, t],
  );

  return {
    exportToExcel,
    loading,
  };
};
