import {api} from "AurionCR/components";
import {API_EXPERT_TYPES, ExpertType} from "./models";

export * from './models'

class Service {
  async createDynamic(title: string) {
    return api.post<ExpertType>(API_EXPERT_TYPES.POST, {title, visitor: true, physician: false})
  }
}

export const ServiceExpertTypes = new Service()
