import { UseFormMethods } from 'react-hook-form';
import { useMemo } from 'react';

export const useFormControlProps = <T>(props: {
  control: UseFormMethods<T>['control'];
  errors: UseFormMethods<T>['errors'];
  name: keyof T;
}) => {
  const { name, control, errors } = props;
  return useMemo(() => {
    return {
      name,
      control,
      error: Array.isArray(errors[name])
        ? undefined
        : (errors[name] as undefined | { message?: string }),
    };
  }, [name, control, errors]);
};
