const API_ENDPOINT = 'MedicalFields'

export const API_MEDICAL_FIELDS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: { id: MedicalField['id'] }) =>  `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: { id: MedicalField['id'] }) => `${API_ENDPOINT}/Delete/${data.id}`,
}

export class MedicalField {
  id!: string
  title: string = ''
  externalCertificateID: string = ''
  externalCertificateDescription: string = ''
  externalCertificateClass: string = ''
  isActive: boolean = true
  isForDoctorOnly: boolean = false
}
