import {api} from "AurionCR/components";
import {API_PHARMA_USERS, PharmaCompanyUser} from "./models";

export * from './models'

class Service {
  async getByID(id: string) {
    return api.get<PharmaCompanyUser>(API_PHARMA_USERS.GET(id))
  }

  async create({pharmaCompanyID, appIdentityUserID}: { pharmaCompanyID: string, appIdentityUserID: string }) {
    return api.post(API_PHARMA_USERS.POST, {pharmaCompanyID, appIdentityUserID})
  }

  async delete(id: string) {
    return api.delete(API_PHARMA_USERS.DELETE({id}))
  }

  async checkExistUserByAppID({pharmaCompanyID, appIdentityUserID}: { pharmaCompanyID: string, appIdentityUserID: string }) {
    const params = {
      filter: `appIdentityUserID=="${appIdentityUserID}"&pharmaCompanyID=="${pharmaCompanyID}"`
    }

    return api.get<{ value: PharmaCompanyUser[] }>(API_PHARMA_USERS.GET_ALL_DYNAMIC, {params});
  }
}

export const ServicePharmaCompanyUsers = new Service()
