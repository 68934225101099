import {PatchModel} from "utils";
import {API_MEMBERS, Member} from "./models";
import {api} from "AurionCR/components";

export * from './models'

class Service {
  createVisitor(data: Partial<Member>) {
    return api.post(API_MEMBERS.POST_VISITOR, data)
  }

  patch(data: PatchModel<Member, 'appIdentityUserID'>) {
    const patchData: Partial<Member> = {
      titleOfNameID: data.titleOfNameID,
      isAllowSMS: data.isAllowSMS,
      isActive: data.isActive,
      expertTypeID: data.expertTypeID,
      userStatusID: data.userStatusID,
      firstName: data.firstName,
      lastName: data.lastName,
      position: data.position,
      email: data.email,
      externalCertificateLicenseID: data.externalCertificateLicenseID,
      primaryMedicalFieldID: data.primaryMedicalFieldID,
      hmoID: data.hmoID,
      hmoName: data.hmoName,
      hmoCityID: data.hmoCityID,
      hospitalID: data.hospitalID,
      hospitalPosition: data.hospitalPosition,
      hospitalDepartmentID: data.hospitalDepartmentID,
      privateWorkPlaceName: data.privateWorkPlaceName,
      otherWorkPlaceID: data.otherWorkPlaceID,
      secondaryMedicalFieldID: data.secondaryMedicalFieldID
    }
    return api.patch(API_MEMBERS.PATCH(data), patchData)
  }

  async resetUsers() {
    return api.delete(API_MEMBERS.RESET)
  }
}

export const ServiceMembers = new Service()
