const _API = 'UserExpertProfiles'

export const API_MEMBERS = {
  API: _API,
  GET_ALL_DYNAMIC: `${_API}/GetAllDynamic`,
  POST: `${_API}/Create`,
  POST_VISITOR: `${_API}/CreateVisitor`,
  PATCH: ({appIdentityUserID}: Pick<Member, 'appIdentityUserID'>) => `${_API}/Patch/${appIdentityUserID}`,
  DELETE: ({ appIdentityUserID }: Pick<Member, 'appIdentityUserID'>) => `${_API}/Delete/${appIdentityUserID}`,
  RESET: `${_API}/Reset`
}

export class Member {
  id!: string
  createdDate!: string
  isActive: boolean = true
  isAllowSMS: boolean = false
  appIdentityUserID!: string
  languageID!: string
  generatePasswordEndDate: string = ''
  email: string = ''
  mobilePhone: string = ''
  titleOfNameID = ''
  firstName: string = ''
  lastName: string = ''
  readonly fullName!: string
  userPhoto: string = ''
  externalCertificateValid = false
  externalCertificateLicenseID: string = ''
  position: string = ''

  expertTypeID!: string | null
  userStatusID!: string | null
  primaryMedicalFieldID!: string | null
  secondaryMedicalFieldID!: string | null

  hmoID!:string
  hmoName: string = ''
  hmoCityID!:string

  hospitalID!:string
  hospitalPosition: string = ''
  hospitalDepartmentID!:string

  privateWorkPlaceName: string = ''
  privateWorkPlaceMedicalPractice: string = ''
  otherWorkPlaceID!: string
}
