const API_ENDPOINT = 'Hmos'

export const API_HMOS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: { id: Hmo['id'] }) =>  `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: { id: Hmo['id'] }) => `${API_ENDPOINT}/Delete/${data.id}`,
}

export class Hmo {
  id!: string
  title: string = ''
  isActive: boolean = true
}
