export const searchInclude = (value: number | string | null | undefined, include: string) => {
  const _value = String(value || '').toLocaleLowerCase();
  const _include = String(include).toLocaleLowerCase();
  return _value.includes(_include);
};

const patterns_: { [x: string]: RegExp } = {
  '#': /\d/,
};
export const templateChar = (value: [string], templates: string[], patterns = patterns_) => {
  const [char] = value;
  const patternValue = templates.find((temp) => patterns_[temp]);
  const tempChar = templates.find((temp) => temp === char);

  if (patternValue) {
    return patterns[patternValue].test(char) ? char : '';
  }
  if (tempChar) {
    return tempChar;
  }
  if (templates[0]) {
    return `${templates[0]}${char}`;
  }
  return '';
};

export const templateString = (value: string, template: string[], patterns = patterns_): string => {
  const _value = Array.from(String(value || ''));

  return _value.reduce((acc, char, i) => {
    const temps: string[] = [];
    for (let j = 0; j < template.length; j++) {
      if (template[j][i]) {
        temps.push(template[j][i]);
      }
    }
    const val = templateChar([char], temps, patterns);
    if (val.length <= 1) {
      return acc + val;
    } else {
      return templateString(acc + val, template, patterns);
    }
  }, '');
};

export const templateValue = (value: string, templates: string[], patterns = patterns_) => {
  let _templates = Array.from(templates);
  const _value = Array.from(String(value || ''));

  return _value.reduce((result, char, i) => {
    _templates = _templates.filter((template) => {
      const pattern = patterns[template[i]];
      return pattern ? pattern.test(char) : !!template[i];
    });
    const isMatch = !!_templates.length;

    result += isMatch
      ? _templates[0][i]
        ? patterns[_templates[0][i]] && patterns[_templates[0][i]].test(char)
          ? char
          : _templates[0][i]
        : ''
      : '';
    return result;
  }, '');
};

export const replaceLabel = (value: string, replacer: (v: string) => string) => {
  return String(value).replace(/#.+?#/gi, (v: string) => {
    const key = v.substring(1, v.length - 1);
    return replacer(key);
  });
};

export const calcRequestLabel = (value: string, replacer: (v: string) => string) => {
  const _value = String(value);
  if (RegExp(/^@.+@$/gi).test(_value)) {
    return replaceLabel(_value.substring(1, _value.length - 1), replacer);
  } else {
    return replacer(_value);
  }
};

const replaceTranslate = (value: string, replacer: (v: string) => string) => {
  return String(value).replace(/{{.+?}}/gi, (v: string) => {
    const key = v.substring(2, v.length - 2);
    return replacer(key);
  });
};
export const calcTranslate = (value: string, payload: { [x: string]: any } = {}) => {
  return replaceTranslate(value, (key) => {
    return payload[key] || '-- --';
  });
};
