const API = 'UserPharmaCompanyProfiles'

export const API_USER_PHARMA_COMPANY = {
  API,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({appIdentityUserID}: Pick<UserPharmaCompanyProfile, 'appIdentityUserID'>) => `${API}/Patch/${appIdentityUserID}`,
  DELETE: ({appIdentityUserID}: Pick<UserPharmaCompanyProfile, 'appIdentityUserID'>) => `${API}/Delete/${appIdentityUserID}`
}

export class PatchUserPharmaCompanyProfile {
  firstName: string = ''
  lastName: string = ''
  userPhoto: string = ''
  isActive: boolean = false
  mobilePhone: string = ''
  languageID: string = ''
  id = ''
  appIdentityUserID = ''
}

export class UserPharmaCompanyProfile extends  PatchUserPharmaCompanyProfile{
  email: string = ''
}
