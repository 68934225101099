const _API = 'PharmaCompanies'

export const API_PHARMA = {
  API: _API,
  GET_ALL_DYNAMIC: `${_API}/GetAllDynamic`,
  POST: `${_API}/Create`,
  PATCH: ({id}: Pick<PharmaCompany, 'id'>) => `${_API}/Patch/${id}`,
  DELETE: ({id}: Pick<PharmaCompany, 'id'>) => `${_API}/Delete/${id}`
}

export class PharmaCompany {
  id!: string
  isActive = true
  logo = ''
  visitorCode = ''
  companyName = ''
  description = ''
  allowDemoForVisitor = false
}
