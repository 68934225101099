import {api, apiStatic, checkEs6AndRun} from "../../../helpers";
import {merge} from "lodash";

export const updateMixin = (mixins: any[], data: any) => {
  return Promise.all(
    mixins.map((mixin: any) => {
      if (mixin.type_ === 'selectMixin') {
        const requests = mixin.mixin_.map((item: any) => {
          const config_: any = {method: item.method, url: item.url};
          if (item.method === 'post') config_.data = {...item.data, [mixin.mainFiled_]: data[mixin.keyFromResponse_]};
          return apiStatic.request(config_);
        });
        return Promise.all([
          ...requests,
          ...[mixin.source_ && requests.length
            ? new Promise(resolve => {
              resolve(undefined);
            }) : null
          ]
        ]);
      } else if (['imageUploader'].includes(mixin.type_)) {
        const {fileNameFromFieldModel: ID, mixin_: request} = mixin;
        if (request.method === 'put') {
          // remove file
          return new Promise((resolve, reject) => {
            apiStatic
              .request(request)
              .then(resolve)
              .catch(reject);
            // no need path main model, because field empy automatical
          });
        } else if (request.method === 'post') {
          // create file
          return new Promise((resolve, reject) => {
            apiStatic
              .request(merge(
                {},
                request,
                {
                  data: {
                    fileName: `${data[ID]}_${mixin.name}.png`
                  }
                }
              ))
              .then(response => {
                const id_ = (ID) ? {[ID]: data[ID]} : {};
                const additions_ = (mixin.addDataToPost) ? data : {};
                // path model where used this file
                api
                  .patch(checkEs6AndRun(mixin.apiPath, data), {
                    ...id_, ...additions_,
                    [mixin.name]: response.data.filePath
                  })
                  .then(resolve)
                  .catch(reject);
              })
              .catch(reject);
          });
        } else {
          return null;
        }
      } else if (mixin.type_ === 'editor') {
        const {fileNameFromFieldModel: ID, mixin_: setRequest} = mixin;
        return new Promise((resolve, reject) => {
          // create/update file
          apiStatic
            .request(merge({}, setRequest, {data: {fileName: `${data[ID]}_${mixin.name}.html`}}))
            .then(response => {
              // update model where used this file
              api
                .patch(checkEs6AndRun(mixin.apiPath, data), {[ID]: data[ID], [mixin.name]: response.data.filePath})
                .then(resolve)
                .catch(reject);
            })
            .catch(reject);
        });
      } else if (mixin.type_ === 'fileUploader') {
        const {fileNameFromFieldModel: ID} = mixin;
        const {put, post} = mixin.mixin_.requests;

        const asyncFileUploader = async () => {
          if (put) await apiStatic.request(put);
          if (post) {
            const response = await apiStatic.request(merge({}, post, {data: {fileName: `${data[ID]}_${mixin.value}`}}));
            const id_ = (ID) ? {[ID]: data[ID]} : {};
            const additions_ = (mixin.addDataToPost) ? data : {};
            const resolve = await api.patch(checkEs6AndRun(mixin.apiPath, data), {
              ...id_, ...additions_,
              [mixin.name]: response.data.filePath
            });
            return resolve;
          }
          return 'done';
        };

        return asyncFileUploader();
      } else {
        return null;
      }
    })
  )
};
