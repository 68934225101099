import { useSelector } from 'react-redux';
import { useMemo } from 'react';

export const useIsRtl = () => {
  const { direction } = useSelector((state) => (state as any).language);

  return useMemo(() => {
    return direction === 2;
  }, [direction]);
};
