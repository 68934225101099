import { useCallback, useState } from 'react';
import { useCurrentUser } from './use-current-user';

export const useGridFilterLanguage = () => {
  const user = useCurrentUser();
  const [languageID, setLanguage] = useState(user.languageID);
  const onFilter = useCallback((payload: { [x: string]: any }) => {
    if (payload.languageID) {
      setLanguage(payload.languageID);
    }
  }, []);

  return {
    onFilter,
    languageID,
  };
};
