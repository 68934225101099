const API = 'ExpertTypes'

export const API_EXPERT_TYPES = {
  API,
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: ({id}: Pick<ExpertType, 'id'>) => `${API}/Patch/${id}`,
  DELETE: ({id}: Pick<ExpertType, 'id'>) => `${API}/Delete/${id}`
}

export class ExpertType {
  id!: string
  isActive: boolean = false
  title: string = ''
  medicalFieldID!: string
  externalCertificateLicenseTypeID: number | string = ''
  externalLinkURL = ""
  licensePrefix: string = ''
  physician: boolean = false
  visitor: boolean = false
  isAllowViewMedicalEvent = false
  isAllowRegisterMedicalEvent = false
  isAllowViewArticle = false
  isAllowPostArticle = false
  isAllowReactArticle = false
  isAllowCommentArticle = false
  isAllowViewSurvey = false
  isAllowVoteSurvey = false
  isAllowViewPhysician = false
  isAllowChatPhysician = false
  isAllowSearchPhysician = false
  isAllowFollowPhysician = false
  isAllowViewComment = false
  isAllowViewReaction = false
  isAllowViewWebinars = false
  isAllowViewPharmaCompanyExpert = false
  isRequireLicenseVerification = false
}
