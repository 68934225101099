import React, { useEffect } from 'react';
import {
  createTheme,
  jssPreset,
  StylesProvider,
  ThemeProvider as MaterialThemeProvider,
} from '@material-ui/core/styles';
import rtl from 'jss-rtl';
import { create } from 'jss';
import themeOptions from 'styles/theme';
import { CssBaseline } from '@material-ui/core';
import { useIsRtl } from 'hooks';

const styleNode = document.createComment('jss-insertion-point');
document.head.insertBefore(styleNode, document.head.firstChild);

const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
  insertionPoint: 'jss-insertion-point',
});

export const themeRTL = createTheme(themeOptions, { direction: 'rtl' });
export const themeLTR = createTheme(themeOptions, { direction: 'ltr' });

export const ThemeProvider: React.FC = ({ children }) => {
  const isRtl = useIsRtl();
  useEffect(() => {
    const el = document.getElementsByTagName('html')[0];
    el?.setAttribute('dir', isRtl ? 'rtl' : 'ltr');
  }, [isRtl]);

  return (
    <StylesProvider jss={jss}>
      <MaterialThemeProvider theme={isRtl ? themeRTL : themeLTR}>
        <CssBaseline />
        {children}
      </MaterialThemeProvider>
    </StylesProvider>
  );
};
