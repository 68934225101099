import {PharmaCompany} from "../pharma";
import {UserCrmProfile} from "../user-profiles";

const _API = 'PharmaCompanyUsers'

export const API_PHARMA_USERS = {
  API: _API,
  GET: (id: string) => `${_API}/Get/${id}`,
  GET_ALL_DYNAMIC: `${_API}/GetAllDynamic`,
  POST: `${_API}/Create`,
  PATCH: ({id}: Pick<PharmaCompanyUser, 'id'>) => `${_API}/Patch/${id}`,
  DELETE: ({id}: Pick<PharmaCompanyUser, 'id'>) => `${_API}/Delete/${id}`
}

export class PharmaCompanyUser {
  id!: string
  isActive = false
  pharmaCompanyID = ''
  appIdentityUserID = ''
  pharmaCompany!: PharmaCompany
  userPharmaCompanyProfile!: UserCrmProfile
  isAdmin = false
  maxCredits = 1
  pharmaCompanyDepartmentID = ''
  companyName = ''
  isGuest = false
}
