import { useCallback } from 'react';
import { LabelTranslateProps } from 'dictionary';
import { useI18n } from 'AurionCR/components';
import { calcTranslate } from 'utils';

export const useTypedTranslate = () => {
  const { t: trans, loading } = useI18n();

  const t = useCallback(
    (...props: LabelTranslateProps) => {
      const [key, payload] = props;
      return calcTranslate(trans(key), payload);
    },
    // eslint-disable-next-line
    [loading],
  );
  return { t };
};
