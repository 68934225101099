import {MedicalEvent} from "../medical-events";
import {ExpertType} from "../expert-types";

const API_ENDPOINT = 'MedicalEventTargetAudiences'

export const API_MEDICAL_EVENTS_TARGET_AUDIENCES = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: { id: MedicalEventTargetAudience['id'] }) => `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: { id: MedicalEventTargetAudience['id'] }) => `${API_ENDPOINT}/Delete/${data.id}`,
}

export class MedicalEventTargetAudience {
  id!: string
  medicalEventID!: MedicalEvent['id']
  expertTypeID!: ExpertType['id']
  rank: number = 0
  isActive: boolean = true
}
