import React, { FC } from 'react';
// style
import 'AurionCR/styles/_main.scss';
import 'styles/index.scss';
// store
import { Provider } from 'react-redux';
import store from 'AurionCR/store';
import storeConfig from 'store/redux';
// layout
import Page404 from 'AurionCR/pages/Page404';
import Layout from 'pages/Layout';
// router
import routesConfig from 'configs/routes';
// notify
import { SnackbarProvider } from 'notistack';
// l18n
import {
  AuthContextProvider,
  I18nContextProvider,
  LoadingOverlayProvider,
  Notifier,
  Routes,
} from 'AurionCR/components';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { AxiosInterceptors } from 'contexts/axios-interceptors';
import { ThemeProvider } from 'contexts/theme-provider';

const App: FC = () => {
  window.authCookieName_ = 'vimed_auth_token_crm';
  window.authRefreshCookieName_ = 'vimed_auth_refresh_token_crm';
  window.authDateCookieName_ = 'vimed_auth_expires_token_crm';

  return (
    <Provider store={store(storeConfig)}>
      <AxiosInterceptors>
        <AuthContextProvider>
          <DndProvider backend={HTML5Backend}>
            <I18nContextProvider apiLabelsFilter='labelSource.key == "crm"'>
              <ThemeProvider>
                <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                  <Notifier />
                  <LoadingOverlayProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Routes layout={Layout} routes={routesConfig} page404={Page404} />
                    </MuiPickersUtilsProvider>
                  </LoadingOverlayProvider>
                </SnackbarProvider>
              </ThemeProvider>
            </I18nContextProvider>
          </DndProvider>
        </AuthContextProvider>
      </AxiosInterceptors>
    </Provider>
  );
};

export default App;
