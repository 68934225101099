const prepareValue = (value?: string) => {
  return String(value).trim().replace(/"/gi, '\\"');
};
const createStart = (count: number, query: string) => {
  return count > 1 ? `(${query})` : query;
};
export const createFilterEquals = <T extends object = {}>(
  name: keyof T | (keyof T)[],
  value: any,
) => {
  const names = Array.isArray(name) ? name : [name];

  return value
    ? createStart(names.length, names.map((_n) => `${_n}=="${prepareValue(value)}"`).join('||'))
    : undefined;
};
export const createFilterNotEquals = <T extends object = {}>(
  name: keyof T | (keyof T)[],
  value: any,
) => {
  const names = Array.isArray(name) ? name : [name];

  return value
    ? createStart(names.length, names.map((_n) => `${_n}!=${prepareValue(value)}`).join('||'))
    : undefined;
};

export const createFilterWithout = <T extends Record<string, any>>(
  name: keyof T | (keyof T)[],
  value: boolean,
) => {
  const fields = Array.isArray(name) ? name : [name];
  return value ? fields.map((field) => `(${field} == "" || ${field} == null)`).join(' && ') : null;
};
export const createFilterWith = <T extends Record<string, any>>(
  name: keyof T | (keyof T)[],
  value: boolean,
) => {
  const fields = Array.isArray(name) ? name : [name];
  return value ? fields.map((field) => `(${field} != "" && ${field} != null)`).join(' && ') : null;
};

export const createFilterContains = <T extends object = {}>(
  name: keyof T | (keyof T)[],
  value: any,
) => {
  const names = Array.isArray(name) ? name : [name];

  return value
    ? `(${names.map((_n) => `${_n}.contains("${prepareValue(value)}")`).join('||')})`
    : null;
};

export const createFilterSmartSearch = <T>(name: keyof T | (keyof T)[], value: any) => {
  const names = Array.isArray(name) ? name : [name];
  if (!value) return null;

  const field = names.map((_name) => `${_name}.replace(" ",String.Empty)`).join('+');
  return createFilterContains(`(${field})`, String(value).replace(/ /g, ''));
};
