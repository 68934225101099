const API_ENDPOINT = 'Tags'

export const API_TAGS = {
  API_ENDPOINT,
  GET_ALL_DYNAMIC: `${API_ENDPOINT}/GetAllDynamic`,
  POST: `${API_ENDPOINT}/Create`,
  PATCH: (data: { id: Tag['id'] }) =>  `${API_ENDPOINT}/Patch/${data.id}`,
  DELETE: (data: { id: Tag['id'] }) => `${API_ENDPOINT}/Delete/${data.id}`,
}

export class Tag {
  id!: string
  title: string = ''
  isActive: boolean = true
}
