import React, {memo} from "react";
import {IDataGridMadel} from "../../model";
import FilterString from "./filter-string";
import FilterSelect from "./filter-select";
import FilterDate from "./filter-date"
import FilterDateMultiple from "./filter-date-multiple"

const Filters = memo((
  {
    model,
    filters,
    onTasks,
    FilterSlot
  }:
    {
      model: IDataGridMadel;
      filters: any;
      onTasks: any;
      FilterSlot?: React.ComponentType<{ onTasks: any }>;
    }
) => {
  if (!model || !model.toolbar || !model.toolbar.visible) return null;

  return <>
    {
      model.toolbar.filters &&
      <div className="toolbar-filters">
        {
          model.toolbar.filters.map((filter) => {
            switch (filter.options?.type) {
              case "string":
                return <FilterString
                  key={filter.field}
                  filter={filter}
                  value={filters[filter.field]}
                  onTasks={onTasks}
                />;
              case "select":
                return <FilterSelect
                  key={filter.field}
                  filter={filter}
                  value={filters[filter.field]}
                  onTasks={onTasks}
                />;
              case "date-multiple":
                return <FilterDateMultiple
                  key={filter.field}
                  filter={filter}
                  value={filters[filter.field]}
                  onTasks={onTasks}
                />;
              case "date":
                return <FilterDate
                  key={filter.field}
                  filter={filter}
                  value={filters[filter.field]}
                  onTasks={onTasks}
                />;
              case "component":
                return filter.options.template &&
                  <filter.options.template key={filter.field} filter={filter} value={filters[filter.field]}
                                           onTasks={onTasks}/>
              default:
                return null
            }
          })
        }
        {!!FilterSlot && <FilterSlot onTasks={onTasks}/>}
      </div>
    }
  </>;
});

export * from './utils'
export default Filters
