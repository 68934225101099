import React, { forwardRef } from 'react';
import Scrollbars, { ScrollbarProps } from 'react-custom-scrollbars';

export interface AppScrollbarsProps extends ScrollbarProps {
  hideHorizontal?: boolean;
  isRtl: boolean;
}

export const AppScrollbars = forwardRef<Scrollbars, AppScrollbarsProps>(
  ({ hideHorizontal = true, isRtl = true, ...rest }, ref) => {
    return (
      <Scrollbars
        ref={ref}
        {...rest}
        renderView={(props) => (
          <div
            {...props}
            style={{
              ...props.style,
              marginLeft: isRtl ? props.style.marginRight : props.style.marginLeft,
              marginRight: isRtl ? props.style.marginLeft : props.style.marginRight,
            }}
          >
            <div>{props.children}</div>
          </div>
        )}
        {...(hideHorizontal
          ? {
              renderTrackHorizontal: () => <div style={{ display: 'none' }} />,
            }
          : {})}
        renderTrackVertical={(props) => (
          <div
            {...props}
            style={{
              ...props.style,
              bottom: '0.2rem',
              top: '0.2rem',
              width: '0.4rem ',
              borderRadius: '3px',
              left: isRtl ? '0.8rem' : 'unset',
              right: isRtl ? 'unset' : '0.8rem',
            }}
          />
        )}
      />
    );
  },
);
